import { useMutation } from '@tanstack/react-query';

import { AuthType } from '@portals/types';

import { ONBOARDING_API_URL } from './onboarding.constants';
import { CreateTenantRequestPayload } from './onboarding.types';
import { ServerError } from '../../types';
import { fetchApiRequest } from '../../utils';
import { generateAuthHeaders } from '../auth/auth.constants';

export function useCreateTenant() {
  return useMutation<
    AuthType,
    ServerError,
    {
      auth: AuthType;
      tenantPayload: CreateTenantRequestPayload;
    }
  >({
    mutationFn: ({ auth, tenantPayload }) => {
      const headers = generateAuthHeaders(auth);

      return fetchApiRequest(`${ONBOARDING_API_URL}/register`, {
        method: 'POST',
        headers,
        body: JSON.stringify(tenantPayload),
      });
    },
    meta: {
      mutationName: 'useCreateTenant',
      baseUrl: `${ONBOARDING_API_URL}/register`,
      method: 'POST',
    },
  });
}

interface UseCreateUserForTenantParams {
  token: string;
}

export function useCreateUserForTenant() {
  const headers = generateAuthHeaders(undefined);

  return useMutation<AuthType, ServerError, UseCreateUserForTenantParams>({
    mutationFn: ({ token }) => {
      return fetchApiRequest(`${ONBOARDING_API_URL}/register_user`, {
        method: 'POST',
        headers,
        body: JSON.stringify({
          token,
          accepted_terms_and_conditions: true,
        }),
      });
    },
    meta: {
      mutationName: 'useCreateUserForTenant',
      baseUrl: `${ONBOARDING_API_URL}/register_user`,
      method: 'POST',
    },
  });
}
