import { Text, Title } from '@mantine/core';
import { notifications } from '@mantine/notifications';
import { motion } from 'framer-motion';
import React, { ReactNode } from 'react';

import { ReactComponent as InfoCircle } from '@portals/icons/bold/info-circle.svg';

export function StepTitle({ children }: { children: ReactNode }) {
  return (
    <Title
      order={2}
      color="blue_gray.9"
      weight={700}
      align="center"
      data-testid="step-title"
    >
      {children}
    </Title>
  );
}

export function StepSubtitle({ children }: { children: ReactNode }) {
  return (
    <Text size="sm" color="blue_gray.5" align="center" mb="lg">
      {children}
    </Text>
  );
}

export function StepAnimationWrapper({
  id,
  children,
}: {
  id: string;
  children: ReactNode;
}) {
  return (
    <motion.div
      className="step"
      key={id}
      style={{
        width: '100%',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
      }}
      transition={{
        duration: 0.15,
      }}
      initial={{
        opacity: 0,
      }}
      animate={{
        opacity: 1,
      }}
      exit={{
        opacity: 0,
      }}
    >
      {children}
    </motion.div>
  );
}

export function showErrorNotification(errorMessage: unknown) {
  const displayedErrorMessage =
    typeof errorMessage !== 'string' ? 'An error occurred' : errorMessage;

  notifications.show({
    title: 'Error',
    message: displayedErrorMessage,
    icon: <InfoCircle />,
    styles: (theme) => ({
      root: {
        backgroundColor: theme.colors.red[4],
        opacity: 0.94,
      },

      icon: {
        color: theme.white,
        backgroundColor: theme.colors.red[4],
      },

      closeButton: {
        color: theme.white,

        '&:hover': {
          backgroundColor: theme.colors.red[1],
        },
      },

      title: {
        color: theme.white,
        fontSize: theme.fontSizes.lg,
      },

      description: {
        color: theme.white,
        fontSize: theme.fontSizes.md,
      },
    }),
  });
}
