import { Button, Divider, Stack } from '@mantine/core';
import { first, upperCase } from 'lodash/fp';
import React from 'react';
import styled from 'styled-components';

import { getStyledThemeColor } from '@portals/utils';

import { useOnboardingWizard } from '../../../../use-onboarding-wizard';

export function AuthorizedDesktop() {
  const { contextData, onNext, setContextData } = useOnboardingWizard();

  const nameAbbreviation = upperCase(first(contextData.auth.email));

  const removeAuth = () =>
    setContextData((prev) => ({
      invite_code: prev.invite_code,
      tenantType: prev.tenantType,
    }));

  return (
    <Stack>
      <UserWrapper>
        <User>
          <Avatar>{nameAbbreviation}</Avatar>

          <div className="user-email">{contextData.auth.email}</div>
        </User>
      </UserWrapper>

      <Button size="md" onClick={onNext}>
        Continue
      </Button>

      <Divider label="OR" labelPosition="center" />

      <Button size="md" variant="default" onClick={removeAuth}>
        Sign up with a different account
      </Button>
    </Stack>
  );
}

const UserWrapper = styled.div`
  width: 100%;
  height: 75px;
  border-radius: 8px;
  border: 1px solid ${getStyledThemeColor('gray200')};
  background-color: ${getStyledThemeColor('gray100')};
`;

const User = styled.div`
  display: grid;
  align-items: center;
  height: 100%;
  grid-template-columns: 44px 1fr;
  grid-column-gap: 20px;
  padding: 0 15px;

  .user-email {
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
    color: ${getStyledThemeColor('gray600')};
  }
`;

const Avatar = styled.div`
  height: 44px;
  width: 44px;
  border-radius: 8px;
  background-color: ${getStyledThemeColor('dark')};
  color: ${getStyledThemeColor('white')};
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 14px;
  font-weight: bold;
  transition: background-color 0.15s ease-in-out, color 0.15s ease-in-out;
  padding-top: 2px;
`;
