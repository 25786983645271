import React from 'react';

import { Responsive } from '@portals/core';

import { AuthorizedDesktop } from './Authorized.desktop';
import { AuthorizedMobile } from './Authorized.mobile';

export function Authorized() {
  return (
    <Responsive>
      <Responsive.Desktop>
        <AuthorizedDesktop />
      </Responsive.Desktop>

      <Responsive.Mobile>
        <AuthorizedMobile />
      </Responsive.Mobile>
    </Responsive>
  );
}
