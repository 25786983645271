import { AuthProvider } from '@descope/react-sdk';
import React from 'react';

import { Responsive } from '@portals/core';

import { LayoutDesktop } from './Layout.desktop';
import { LayoutMobile } from './Layout.mobile';

export function Layout() {
  return (
    <>
      <AuthProvider projectId={process.env.NX_DESCOPE_PROJECT_ID}>
        <Responsive>
          <Responsive.Desktop>
            <LayoutDesktop />
          </Responsive.Desktop>

          <Responsive.Mobile>
            <LayoutMobile />
          </Responsive.Mobile>
        </Responsive>
      </AuthProvider>
    </>
  );
}
