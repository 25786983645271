import { Descope, getSessionToken } from '@descope/react-sdk';
import { createStyles } from '@mantine/core';
import { jwtDecode } from 'jwt-decode';
import React, { ComponentPropsWithoutRef } from 'react';

import { useCreateUserForTenant } from '@portals/api/onboarding';
import {
  captureDescopeError,
  sendDescopeErrorReport,
} from '@portals/framework';

import { Authorized } from './Authorized';
import { OnboardingJWT } from '../../../types/common';
import { useOnboardingWizard } from '../../../use-onboarding-wizard';
import { showErrorNotification } from '../../common';

export function AuthenticationMobile() {
  const { classes } = useStyles();
  const { setContextData, onNext, contextData } = useOnboardingWizard();

  const createUserForTenant = useCreateUserForTenant();

  const onSubmit: ComponentPropsWithoutRef<
    typeof Descope
  >['onSuccess'] = async (e) => {
    const sessionToken = getSessionToken();

    const parsedJWT = jwtDecode<OnboardingJWT>(e.detail.sessionJwt);

    try {
      const auth = await createUserForTenant.mutateAsync({
        token: sessionToken,
      });

      setContextData((curr) => ({
        ...curr,
        email: e.detail.user.email,
        auth,
        companyName: parsedJWT.company_name,
      }));

      onNext();
    } catch (error) {
      showErrorNotification((e as any)?.error);
    }
  };

  if (contextData.auth) {
    return <Authorized />;
  }

  return (
    <div className={classes.container}>
      <Descope
        flowId="onboarding"
        onError={captureDescopeError}
        onSuccess={onSubmit}
        logger={{
          debug: (title: string, description?: string) => {
            return;
          },
          info: (title, description, state) => {
            sendDescopeErrorReport(state, 'Onboarding > AuthenticationMobile');
          },
          warn: () => {
            return;
          },
          error: () => {
            return;
          },
        }}
      />
    </div>
  );
}

const useStyles = createStyles((theme) => ({
  container: {
    padding: theme.spacing.md,
  },
}));
