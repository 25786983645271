import { Button, Stack } from '@mantine/core';
import React, { useRef } from 'react';

import { TenantType } from '@portals/types';

import { ReactComponent as SuccessIcon } from '../../../assets/success.svg';
import { OnboardingWizardContextDataType } from '../../../types/wizard';
import { useOnboardingWizard } from '../../../use-onboarding-wizard';
import { StepSubtitle, StepTitle } from '../../common';

export function CongratulationsDesktop() {
  const { contextData } = useOnboardingWizard();

  const localContextData = useRef<OnboardingWizardContextDataType>(contextData);

  const onContinue = () => {
    let url;

    switch (localContextData.current.tenantType) {
      case TenantType.Organization:
        url = process.env.NX_ORGANIZATIONS_PORTAL_URL;
        break;

      case TenantType.Partner:
      default:
        url = process.env.NX_PARTNERS_PORTAL_URL;
    }

    const encodedAuth = btoa(JSON.stringify(localContextData.current.auth));

    localStorage.removeItem('onboarding');
    window.location.replace(`${url}?auth=${encodedAuth}`);
  };

  return (
    <Stack align="center" spacing={0} w={370}>
      <SuccessIcon className="mb-4" />
      <StepTitle>Congratulations!</StepTitle>
      <StepSubtitle>Your tenant was successfully created.</StepSubtitle>

      <Button
        onClick={onContinue}
        size="md"
        sx={{ width: '100%' }}
        data-testid="go-to-your-portal-button"
      >
        {contextData.classification === 'channel_partner'
          ? 'Go to your portal'
          : 'Customize your portal'}
      </Button>
    </Stack>
  );
}
