import { Box, Button, createStyles, Stack, Title } from '@mantine/core';
import { first, upperCase } from 'lodash/fp';
import React from 'react';

import { useOnboardingWizard } from '../../../../use-onboarding-wizard';

export function AuthorizedMobile() {
  const { classes } = useStyles();
  const { contextData, onNext, setContextData } = useOnboardingWizard();

  const nameAbbreviation = upperCase(first(contextData.auth.email));

  const removeAuth = () =>
    setContextData((prev) => ({
      invite_code: prev.invite_code,
      tenantType: prev.tenantType,
    }));

  return (
    <Box className={classes.container}>
      <Stack h="100%" justify="center">
        <Title order={4}>Your account</Title>

        <div className={classes.userWrapper}>
          <div className={classes.user}>
            <div className={classes.avatar}>{nameAbbreviation}</div>

            <div className="user-email">{contextData.auth.email}</div>
          </div>
        </div>

        <Button size="md" variant="default" onClick={removeAuth}>
          Sign up with a different account
        </Button>

        <Button size="md" onClick={onNext}>
          Continue
        </Button>
      </Stack>
    </Box>
  );
}

const useStyles = createStyles((theme) => ({
  container: {
    display: 'grid',
    gridTemplateRows: '1fr min-content',
    height: '100%',
  },
  userWrapper: {
    width: '100%',
    height: '75px',
    borderRadius: '8px',
    border: `1px solid ${theme.colors.gray[2]}`,
    backgroundColor: theme.colors.gray[1],
  },
  user: {
    display: 'grid',
    alignItems: 'center',
    height: '100%',
    gridTemplateColumns: '44px 1fr',
    gap: '20px',
    padding: '0 15px',

    '.user-email': {
      textOverflow: 'ellipsis',
      overflow: 'hidden',
      whiteSpace: 'nowrap',
      color: theme.colors.gray[6],
    },
  },
  avatar: {
    height: '44px',
    width: '44px',
    borderRadius: '8px',
    backgroundColor: theme.colors.gray[8],
    color: theme.white,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    fontSize: '14px',
    fontWeight: 'bold',
  },
}));
