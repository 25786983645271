import { Box, createStyles, Grid } from '@mantine/core';
import { AnimatePresence } from 'framer-motion';
import lottie, { AnimationItem } from 'lottie-web';
import React, { useRef } from 'react';

import * as animation from '../../assets/animation.json';
import { ReactComponent as Title } from '../../assets/title.svg';
import { useOnboardingWizard } from '../../use-onboarding-wizard';
import { StepAnimationWrapper } from '../common';

export function LayoutDesktop() {
  const { classes } = useStyles();
  const { currentStep } = useOnboardingWizard();

  const animationRef = useRef<AnimationItem>();
  const onRef = (node: HTMLDivElement) => {
    if (!node || !!animationRef.current) return;

    animationRef.current = lottie.loadAnimation({
      container: node,
      renderer: 'svg',
      loop: true,
      autoplay: true,
      animationData: animation,
    });
  };

  return (
    <Grid m={0} className={classes.container}>
      <Grid.Col span={4} p={0} className={classes.leftPanel}>
        <Box className={classes.titleWrapper}>
          <Title style={{ width: '100%', maxWidth: 560 }} />
        </Box>

        <Box ref={onRef} className={classes.animationContainer} />
      </Grid.Col>

      <Grid.Col
        span={8}
        sx={{
          display: 'grid',
          alignItems: 'center',
          position: 'relative',
          height: '100%',
        }}
      >
        <AnimatePresence initial={false}>
          <StepAnimationWrapper id={currentStep.id}>
            {currentStep.component}
          </StepAnimationWrapper>
        </AnimatePresence>
      </Grid.Col>
    </Grid>
  );
}

const useStyles = createStyles((theme) => ({
  container: {
    height: '100%',
    background: theme.white,
  },
  leftPanel: {
    backgroundColor: theme.colors.gray[0],
    display: 'grid',
    alignItems: 'center',
    justifyContent: 'center',
    position: 'relative',
  },
  animationContainer: {
    width: '100%',
    position: 'absolute',
    left: 0,

    svg: {
      maxWidth: '100%',
    },
  },
  titleWrapper: {
    position: 'absolute',
    width: '100%',
    top: '20%',
    display: 'flex',
    justifyContent: 'center',
    zIndex: 1,
    padding: '0 30px',
  },
}));
