import React from 'react';

import { Responsive } from '@portals/core';

import { TenantCardProps } from './tenant-card.types';
import { TenantCardDesktop } from './TenantCard.desktop';
import { TenantCardMobile } from './TenantCard.mobile';

export function TenantCard(props: TenantCardProps) {
  return (
    <Responsive>
      <Responsive.Desktop>
        <TenantCardDesktop {...props} />
      </Responsive.Desktop>

      <Responsive.Mobile>
        <TenantCardMobile {...props} />
      </Responsive.Mobile>
    </Responsive>
  );
}
