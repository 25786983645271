import React from 'react';

import { Responsive } from '@portals/core';

import { CongratulationsDesktop } from './Congratulations.desktop';
import { CongratulationsMobile } from './Congratulations.mobile';

export function Congratulations() {
  return (
    <Responsive>
      <Responsive.Desktop>
        <CongratulationsDesktop />
      </Responsive.Desktop>

      <Responsive.Mobile>
        <CongratulationsMobile />
      </Responsive.Mobile>
    </Responsive>
  );
}
