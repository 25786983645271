import { Button, Stack, Title } from '@mantine/core';
import React, { ReactNode, useCallback } from 'react';

import { useCreateTenant, useUpdateUser } from '@portals/api/onboarding';
import { ReactComponent as Box } from '@portals/icons/linear/box-1.svg';
import { ReactComponent as Shop } from '@portals/icons/linear/shop.svg';
import { AuthType, TenantType } from '@portals/types';

import { TenantCard } from './TenantCard';
import { OnboardingWizardContextDataType } from '../../../types/wizard';
import { useOnboardingWizard } from '../../../use-onboarding-wizard';

interface TenantItem {
  classification: OnboardingWizardContextDataType['classification'];
  tenantType: TenantType;
  label: string;
  icon: ReactNode;
  description: string;
}

const TENANT_TYPE_OPTIONS: TenantItem[] = [
  {
    classification: 'manufacturer',
    tenantType: TenantType.Partner,
    label: 'Manufacturer',
    icon: <Box />,
    description:
      'Cloudify your products, support your customers, and introduce new subscription based business models',
  },
  {
    classification: 'channel_partner',
    tenantType: TenantType.Partner,
    label: 'Channel Partner',
    icon: <Shop />,
    description:
      'Craft a unique Solution-as-a-Service and support your customers remotely - all through a single pane of glass',
  },
];

export function TenantTypeSelectionMobile() {
  const { onPrev, onNext, contextData, setContextData, isNextDisabled } =
    useOnboardingWizard();
  const updateUser = useUpdateUser();
  const createTenant = useCreateTenant();

  const onContinue = useCallback(async () => {
    if (
      !contextData.auth ||
      !contextData.tenantType ||
      !contextData.companyName ||
      !contextData.classification
    ) {
      return;
    }

    const isDemoFlow = window.location.hostname.includes('demo');

    try {
      const auth: AuthType = await createTenant.mutateAsync({
        auth: contextData.auth,
        tenantPayload: {
          is_internal: isDemoFlow,
          company_type: contextData.tenantType,
          company_name: contextData.companyName,
          partner_classification: contextData.classification,
          invite_code: contextData.invite_code,
        },
      });

      await updateUser.mutateAsync({ auth, name: contextData.name });

      setContextData({ ...contextData, auth });

      onNext();
    } catch (e) {
      console.error(e);
    }
  }, [contextData, createTenant, onNext, setContextData, updateUser]);

  const onTenantSelection = useCallback(
    (classification: OnboardingWizardContextDataType['classification']) => {
      setContextData((curr) => ({
        ...curr,
        classification,
      }));
    },
    [setContextData]
  );

  return (
    <Stack w="100%" h="100%" justify="center" spacing="xl" px={32}>
      <Title order={3}>{contextData.companyName} is</Title>

      {TENANT_TYPE_OPTIONS.map(
        ({ classification, label, icon, description }) => (
          <TenantCard
            key={classification}
            classification={classification}
            isSelected={contextData.classification === classification}
            onSelect={onTenantSelection}
            icon={icon}
            label={label}
            description={description}
          />
        )
      )}

      <Stack>
        <Button
          onClick={onContinue}
          type="submit"
          size="md"
          disabled={isNextDisabled}
          loading={createTenant.isLoading || updateUser.isLoading}
        >
          Create Account
        </Button>

        <Button
          onClick={onPrev}
          disabled={createTenant.isLoading || updateUser.isLoading}
          variant="default"
          size="md"
        >
          Back
        </Button>
      </Stack>
    </Stack>
  );
}
