import { Button, Grid, Stack } from '@mantine/core';
import React, { ReactNode, useCallback } from 'react';

import { useCreateTenant, useUpdateUser } from '@portals/api/onboarding';
import { ChannelPartnerSVG } from '@portals/assets';
import { AuthType, TenantType } from '@portals/types';

import { TenantCardDesktop } from './TenantCard/TenantCard.desktop';
import { ReactComponent as ManufacturerIcon } from '../../../assets/manufacturer.svg';
import { OnboardingWizardContextDataType } from '../../../types/wizard';
import { useOnboardingWizard } from '../../../use-onboarding-wizard';
import { showErrorNotification, StepSubtitle, StepTitle } from '../../common';

interface TenantItem {
  classification: OnboardingWizardContextDataType['classification'];
  tenantType: TenantType;
  label: string;
  icon: ReactNode;
  description: string;
}

const TENANT_TYPE_OPTIONS: TenantItem[] = [
  {
    classification: 'manufacturer',
    tenantType: TenantType.Partner,
    label: 'Manufacturer',
    icon: <ManufacturerIcon data-testid="manufacturer-icon" />,
    description:
      'Cloudify your products, support your customers, and introduce new subscription based business models',
  },
  {
    classification: 'channel_partner',
    tenantType: TenantType.Partner,
    label: 'Channel Partner',
    icon: <ChannelPartnerSVG data-testid="channel-partner-icon" />,
    description:
      'Craft a unique Solution-as-a-Service and support your customers remotely - all through a single pane of glass',
  },
];

export function TenantTypeSelectionDesktop() {
  const { onPrev, onNext, contextData, setContextData, isNextDisabled } =
    useOnboardingWizard();
  const updateUser = useUpdateUser();
  const createTenant = useCreateTenant();

  const onContinue = useCallback(async () => {
    if (
      !contextData.auth ||
      !contextData.tenantType ||
      !contextData.companyName ||
      !contextData.classification
    ) {
      return;
    }

    const isDemoFlow = window.location.hostname.includes('demo');

    try {
      const auth: AuthType = await createTenant.mutateAsync({
        auth: contextData.auth,
        tenantPayload: {
          is_internal: isDemoFlow,
          company_type: contextData.tenantType,
          company_name: contextData.companyName,
          partner_classification: contextData.classification,
          invite_code: contextData.invite_code,
        },
      });

      await updateUser.mutateAsync({ auth, name: contextData.name });

      setContextData({ ...contextData, auth });

      onNext();
    } catch (e) {
      showErrorNotification((e as any)?.error);
    }
  }, [contextData, createTenant, onNext, setContextData, updateUser]);

  const onTenantSelection = useCallback(
    (classification: OnboardingWizardContextDataType['classification']) => {
      setContextData((curr) => ({
        ...curr,
        classification,
      }));
    },
    [setContextData]
  );

  return (
    <Stack
      sx={() => ({
        maxWidth: 1280,
        padding: '0 100px',
      })}
      align="center"
    >
      <Stack align="center">
        <StepTitle>{contextData.companyName} is:</StepTitle>

        <StepSubtitle>
          Which one of the following best describes your organization?
        </StepSubtitle>

        <Grid mb={50}>
          {TENANT_TYPE_OPTIONS.map(
            ({ classification, label, icon, description }) => (
              <Grid.Col span={6} key={classification}>
                <TenantCardDesktop
                  classification={classification}
                  isSelected={contextData.classification === classification}
                  onSelect={onTenantSelection}
                  icon={icon}
                  label={label}
                  description={description}
                />
              </Grid.Col>
            )
          )}
        </Grid>

        <Stack sx={{ maxWidth: 480, width: '100%' }}>
          <Button
            onClick={onContinue}
            type="submit"
            size="md"
            data-testid="create-account-button"
            disabled={isNextDisabled}
            loading={createTenant.isLoading || updateUser.isLoading}
          >
            Create Account
          </Button>

          <Button
            onClick={onPrev}
            disabled={createTenant.isLoading || updateUser.isLoading}
            variant="default"
            size="md"
          >
            Back
          </Button>
        </Stack>
      </Stack>
    </Stack>
  );
}
