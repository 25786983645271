import { Card, createStyles, Stack, Text } from '@mantine/core';
import React from 'react';

import { TenantCardProps } from './tenant-card.types';

export function TenantCardMobile({
  label,
  onSelect,
  icon,
  classification,
  isSelected,
  description,
  disabled,
}: TenantCardProps) {
  const { classes, cx } = useStyles({ isSelected, disabled });

  return (
    <Card
      className={cx(classes.container, { selected: isSelected })}
      onClick={() => onSelect(classification)}
    >
      <Stack align="center" spacing="xs">
        {icon}

        <Text size="md" color="gray.9" align="center" weight={600}>
          {label}
        </Text>

        <Text size="sm" color="gray.6" align="center">
          {description}
        </Text>
      </Stack>
    </Card>
  );
}

const useStyles = createStyles(
  (theme, params: { isSelected: boolean; disabled?: boolean }) => ({
    container: {
      flexShrink: 0,
      backgroundColor: theme.colors.gray[1],
      radius: theme.radius.md,
      transition: 'all 0.15s ease-in-out',

      '&.selected': {
        boxShadow: `0 0 0 2px ${theme.colors.blue_accent[4]}`,
      },
    },
  })
);
