import { useMutation } from '@tanstack/react-query';

import { AuthType } from '@portals/types';

import { USERS_API_URL } from './users.constants';
import { fetchApiRequest } from '../../utils';
import { generateAuthHeaders } from '../auth/auth.constants';

export function useUpdateUser() {
  return useMutation({
    mutationFn: ({ auth, name }: { auth: AuthType; name?: string }) => {
      const headers = generateAuthHeaders(auth);

      return fetchApiRequest(`${USERS_API_URL}/${auth.id}`, {
        method: 'PATCH',
        headers: headers,
        body: JSON.stringify({ name }),
      });
    },
    meta: {
      mutationName: 'useUpdateUser',
      baseUrl: `${USERS_API_URL}/:id`,
      method: 'PATCH',
    },
  });
}
