import { getAuthHeaders } from '@portals/redux/actions/auth';
import { AuthType } from '@portals/types';

export function generateAuthHeaders(auth: AuthType | undefined) {
  const headers = new Headers({
    'Content-Type': 'application/json',
    cache: 'no-store',
  });
  const authHeaders = getAuthHeaders(auth);

  authHeaders.forEach((value, key) => {
    headers.append(key, value);
  });

  return headers;
}
