import React from 'react';

import { Responsive } from '@portals/core';

import { AuthenticationDesktop } from './Authentication.desktop';
import { AuthenticationMobile } from './Authentication.mobile';

export function Authentication() {
  return (
    <Responsive>
      <Responsive.Desktop>
        <AuthenticationDesktop />
      </Responsive.Desktop>

      <Responsive.Mobile>
        <AuthenticationMobile />
      </Responsive.Mobile>
    </Responsive>
  );
}
