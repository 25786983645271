import { LoadingOverlay } from '@mantine/core';
import React, { useEffect, useRef, useState } from 'react';
import { useLocalStorage } from 'react-use';

import { Pendo } from '@portals/analytics';
import { AuthenticationMethodType } from '@portals/api/auth';
import { WizardProvider } from '@portals/framework';
import { TenantType } from '@portals/types';

import { Layout } from './Layout';
import { STEPS } from '../constants/wizard';
import { OnboardingWizardContextDataType, StepIdEnum } from '../types/wizard';

const getInitialStep = (contextData: OnboardingWizardContextDataType) => {
  const isUserCreated = contextData.auth;
  const isClassificationSelected = contextData.classification;
  const isTenantCreated =
    isUserCreated && isClassificationSelected && contextData.auth?.tenant;

  return isTenantCreated
    ? StepIdEnum.Congratulations
    : StepIdEnum.TenantTypeSelection;
};

function getInviteCode() {
  const searchParams = new URLSearchParams(window.location.search);

  return searchParams.get('invite_code');
}

export function Onboarding() {
  const [persistedOnboarding, setPersistedOnboarding] = useLocalStorage<
    Partial<OnboardingWizardContextDataType>
  >('onboarding', {});

  const [isInitial, setIsInitial] = useState(true);
  const [contextData, setContextData] =
    useState<OnboardingWizardContextDataType>({
      flowType: AuthenticationMethodType.Email,
      classification: 'channel_partner',
      ...persistedOnboarding,
      tenantType: TenantType.Partner,
      invite_code: getInviteCode(),
    });

  const initialStep = useRef(StepIdEnum.Authentication);

  useEffect(
    function configurePendo() {
      // Pendo.initialize() was called already
      if (Pendo.isReady()) {
        if (contextData.auth && Pendo.isAnonymousVisitor()) {
          // User is logged in, but Pendo visitor is still set to anonymous
          Pendo.identify({
            visitor: {
              id: contextData.auth?.email,
              firstName: contextData.auth?.name?.split(' ')?.[0],
            },
            account: {
              id: contextData.auth?.tenant?.display_name,
              support_email: 'support@xyte.io',
            },
          });
        }
      } else {
        // Pendo.initialize() wasn't called yet
        if (contextData.auth) {
          // Pendo is not ready, initializing with contextData.authenticated visitor data
          Pendo.initializeAuthenticated({
            visitor: {
              id: contextData.auth?.email,
              firstName: contextData?.auth.name?.split(' ')?.[0],
            },
            account: {
              id: contextData.auth?.tenant?.display_name,
              support_email: 'support@xyte.io',
            },
          });
        } else {
          // Pendo is not ready, initializing with anonymous visitor data
          Pendo.initializeAnonymous();
        }
      }
    },
    [contextData.auth]
  );

  useEffect(
    function persistAuthInLocalStorage() {
      setPersistedOnboarding(contextData);
    },
    [contextData, setPersistedOnboarding]
  );

  const checkAuthorization = () => {
    if (!contextData.auth) {
      setIsInitial(false);

      return;
    }

    initialStep.current = getInitialStep(contextData);

    setIsInitial(false);
  };

  if (isInitial) {
    checkAuthorization();
  }

  return isInitial || !initialStep.current ? (
    <LoadingOverlay visible />
  ) : (
    <WizardProvider<OnboardingWizardContextDataType, StepIdEnum>
      steps={STEPS}
      initialStep={initialStep.current}
      contextData={contextData}
      setContextData={setContextData}
    >
      <Layout />
    </WizardProvider>
  );
}
