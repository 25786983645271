import { createStyles, Stack, Text, Title } from '@mantine/core';
import React, { useEffect } from 'react';

import congratulationsSrc from '../../../assets/congratulations.png';

export function CongratulationsMobile() {
  const { classes } = useStyles();

  useEffect(() => {
    localStorage.removeItem('onboarding');
  }, []);

  return (
    <Stack align="center" justify="center" h="100%" px={32} spacing="xl">
      <img
        src={congratulationsSrc}
        alt="Congratulations"
        className={classes.asset}
      />

      <Title order={3}>Congratulations!</Title>

      <Stack spacing="xl">
        <Stack spacing={0}>
          <Text color="gray.7" size="md" align="center">
            Your tenant was successfully created.
          </Text>
          <Text color="gray.7" size="md" align="center">
            Please check your email to confirm registration and proceed with
            your onboarding process
          </Text>
        </Stack>

        {/*<Text color="gray.7" size="md" align="center">*/}
        {/*  In the meantime, please use the following assets:*/}
        {/*</Text>*/}
      </Stack>

      {/*<Button*/}
      {/*  w="100%"*/}
      {/*  color="blue.0"*/}
      {/*  classNames={{*/}
      {/*    root: classes.card,*/}
      {/*    label: classes.cardLabel,*/}
      {/*  }}*/}
      {/*  onClick={() => console.log('lol')}*/}
      {/*  size="xl"*/}
      {/*  h={95}*/}
      {/*  px="md"*/}
      {/*>*/}
      {/*  <Group align="center" spacing="md" w="100%">*/}
      {/*    <Box bg="blue.6" className={classes.icon}>*/}
      {/*      <Book />*/}
      {/*    </Box>*/}

      {/*    <Text color="blue.6" size="md" weight={600}>*/}
      {/*      Xyte's Tutorial*/}
      {/*    </Text>*/}
      {/*  </Group>*/}

      {/*  <ArrowRight1 className={classes.arrow} />*/}
      {/*</Button>*/}
    </Stack>
  );
}

const useStyles = createStyles((theme) => ({
  asset: {
    width: 64,
    height: 64,
  },
  card: {
    background: theme.colors.blue[0],
    borderRadius: theme.radius.lg,
  },
  cardLabel: {
    width: '100%',
    display: 'flex',
    justifyItems: 'space-between',
    alignContent: 'center',
  },
  icon: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    borderRadius: '50%',
    width: 38,
    height: 38,
    svg: { '*': { fill: 'white' } },
  },
  arrow: {
    color: theme.colors.blue[6],
  },
}));
