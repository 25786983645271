import {
  ActionIcon,
  Box,
  Button,
  createStyles,
  Group,
  SimpleGrid,
  Space,
  Stack,
  Text,
} from '@mantine/core';
import { startsWith } from 'lodash/fp';
import React, { useState } from 'react';
import { Helmet, HelmetProvider } from 'react-helmet-async';
import { useOrientation } from 'react-use';

import { XyteLogo } from '@portals/assets';
import { MantineThemeProvider } from '@portals/core';
import { ReactComponent as ArrowLeft } from '@portals/icons/linear/arrow-left-1.svg';
import { VerticalScrollBar } from '@portals/scrollbar';

import splashSrc from '../../assets/splash.png';
import { StepIdEnum } from '../../types/wizard';
import { useOnboardingWizard } from '../../use-onboarding-wizard';

interface SplashProps {
  onContinue: () => void;
}

const Splash = ({ onContinue }: SplashProps) => {
  const { classes, cx } = useSplashStyles();
  const orientation = useOrientation();

  const isLandscape = startsWith('landscape', orientation.type);

  if (isLandscape) {
    return (
      <Box className={cx(classes.commonContainer, classes.landscapeContainer)}>
        <SimpleGrid cols={2}>
          <Stack>
            <img src={splashSrc} alt="splash" />
          </Stack>

          <Stack align="center" justify="center" className="splash-content">
            <XyteLogo width={144} />

            <Text
              className="splash-title"
              color="indigo.7"
              align="center"
              px={32}
              mt={32}
            >
              One Platform That Turns Your Devices Into Services
            </Text>

            <Button
              radius="md"
              fullWidth
              size="lg"
              sx={{ flexShrink: 0 }}
              onClick={onContinue}
            >
              Get started
            </Button>
          </Stack>
        </SimpleGrid>
      </Box>
    );
  }

  return (
    <Box className={cx(classes.commonContainer, classes.desktopContainer)}>
      <XyteLogo width={144} />

      <Text
        className="splash-title"
        color="indigo.7"
        align="center"
        px={32}
        mt={32}
      >
        One Platform That Turns Your Devices Into Services
      </Text>

      <img src={splashSrc} alt="splash" />

      <Button
        radius="md"
        fullWidth
        size="lg"
        sx={{ flexShrink: 0 }}
        onClick={onContinue}
      >
        Get started
      </Button>
    </Box>
  );
};

export const LayoutMobile = () => {
  const { theme, classes } = useLayoutStyles();
  const { currentStep, isPrevDisabled, onPrev } = useOnboardingWizard();

  const [isDisplaySplash, setIsDisplaySplash] = useState(true);

  const onBack = () => {
    if (isPrevDisabled && currentStep.id === StepIdEnum.Authentication) {
      setIsDisplaySplash(true);
    } else {
      onPrev();
    }
  };

  return (
    <HelmetProvider>
      <MantineThemeProvider
        inherit
        theme={{
          globalStyles: () => ({
            html: {
              overflow: 'hidden',
            },
            body: {
              background: theme.white,
              height: '100dvh',

              '*': {
                fontFamily: "'Roboto', sans-serif !important",
              },

              input: {
                fontSize: '16px !important',
              },

              button: {
                // TS doesn't like "!important" in the object value
                // @ts-ignore
                fontWeight: 'normal !important',
              },
            },
          }),
        }}
      >
        <Helmet>
          <link rel="preconnect" href="https://fonts.googleapis.com" />
          <link
            rel="preconnect"
            href="https://fonts.gstatic.com"
            crossOrigin="anonymous"
          />
          <link
            href="https://fonts.googleapis.com/css2?family=Roboto:wght@100;300;400;500;700&family=Source+Sans+Pro:wght@200;300;400;600;700&display=swap"
            rel="stylesheet"
          />
        </Helmet>
        <Box className={classes.container}>
          {isDisplaySplash ? (
            <VerticalScrollBar>
              <Splash onContinue={() => setIsDisplaySplash(false)} />
            </VerticalScrollBar>
          ) : (
            <VerticalScrollBar>
              <Box className={classes.stepWrapper}>
                <Space h={32} />

                <Group
                  align="center"
                  position="center"
                  w="100%"
                  className={classes.header}
                >
                  {currentStep.id === StepIdEnum.Congratulations ? null : (
                    <ActionIcon
                      className={classes.backBtn}
                      onClick={onBack}
                      color="gray.8"
                    >
                      <ArrowLeft />
                    </ActionIcon>
                  )}
                </Group>

                <Stack h="100%" w="100%">
                  {currentStep.component}
                </Stack>

                <Space h={32} />
              </Box>
            </VerticalScrollBar>
          )}
        </Box>
      </MantineThemeProvider>
    </HelmetProvider>
  );
};

const useLayoutStyles = createStyles((theme) => ({
  container: {
    height: '100dvh',
    width: '100%',
    background: theme.white,
    position: 'fixed',
  },
  stepWrapper: {
    height: 'fit-content',
    minHeight: '100%',
    width: '100%',
    display: 'grid',
    gridTemplateRows: 'min-content min-content 1fr min-content',
    gap: theme.spacing.lg,
  },
  header: {
    position: 'relative',
  },
  backBtn: {
    position: 'absolute',
    left: 24,
  },
}));

const useSplashStyles = createStyles((theme) => ({
  commonContainer: {
    '.splash-title': {
      fontSize: '1.2em',
    },

    img: {
      width: '100%',
      maxWidth: 332,
      maxHeight: 314,
    },
  },
  desktopContainer: {
    height: '100%',
    width: '100%',
    padding: '64px 32px',
    display: 'grid',
    gridTemplateRows: 'min-content min-content 1fr min-content',
    gridTemplateColumns: '1fr',
    justifyItems: 'center',
    alignItems: 'center',
    position: 'fixed',
  },
  landscapeContainer: {
    height: '100%',
    width: '100%',
    padding: '64px 32px',
    display: 'grid',
    gridTemplateRows: 'min-content min-content 1fr min-content',
    gridTemplateColumns: '1fr',
    justifyItems: 'center',
    alignItems: 'center',
    position: 'fixed',

    '.splash-title': {
      fontSize: '1.2em',
    },

    img: {
      width: '100%',
      maxWidth: 332,
      maxHeight: 314,
    },
  },
}));
