import { AuthenticationMethodType } from '@portals/api/auth';
import { PartnerClassificationType } from '@portals/api/onboarding';
import { AuthType, TenantType } from '@portals/types';

export enum StepIdEnum {
  Authentication = 'authentication',
  TenantTypeSelection = 'tenantTypeSelection',
  Congratulations = 'congratulations',
}

export interface OnboardingWizardContextDataType {
  email?: string;
  name?: string;
  companyName?: string;
  tenantType?: TenantType;
  googleToken?: string;
  flowType?: AuthenticationMethodType;
  auth?: AuthType;
  classification?: PartnerClassificationType;
  invite_code: string | null;
}
