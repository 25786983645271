import { Anchor, Badge, Box, createStyles, Stack, Text } from '@mantine/core';
import React from 'react';

import { ReactComponent as TickCircle } from '@portals/icons/linear/tick-circle.svg';

import { TenantCardProps } from './tenant-card.types';

export function TenantCardDesktop({
  label,
  onSelect,
  icon,
  classification,
  isSelected,
  description,
  disabled,
}: TenantCardProps) {
  const { classes, cx } = useStyles({ isSelected, disabled });

  return (
    <Stack
      className={cx(classes.container, { selected: isSelected })}
      onClick={() => !disabled && onSelect(classification)}
      spacing={0}
      align="center"
    >
      {disabled && <Badge className={classes.badge}>Coming Soon</Badge>}

      {icon}

      <Text
        size="xl"
        weight={600}
        className={classes.label}
        mt={36}
        data-testid={`tenant-card-${label}`}
      >
        {label}
      </Text>

      <Text
        size="xs"
        weight={600}
        mt="xs"
        align="center"
        className={classes.description}
      >
        {description}
      </Text>

      {disabled && (
        <Anchor
          href="https://calendly.com/d/dhx-zrq-7jm/demo-with-xyte"
          target="_blank"
          className={classes.contactLink}
        >
          Contact us
        </Anchor>
      )}

      {isSelected ? (
        <Box className={classes.selectedIcon}>
          <TickCircle />
        </Box>
      ) : null}
    </Stack>
  );
}

const useStyles = createStyles(
  (theme, params: { isSelected: boolean; disabled?: boolean }) => ({
    container: {
      maxWidth: 350,
      height: '100%',
      border: `2px solid ${
        params.isSelected
          ? theme.colors.blue_accent[4]
          : theme.colors.blue_gray[0]
      }`,
      position: 'relative',
      padding: `28px ${theme.spacing.md} 36px`,
      borderRadius: theme.radius.md,
      transition: 'all 0.15s ease-in-out',
      cursor: 'pointer',

      '&:hover': {
        boxShadow: '0px 11px 18px rgba(38, 50, 56, 0.1)',
      },

      svg: {
        width: '100%',
        mixBlendMode: params.disabled ? 'luminosity' : 'revert',
      },
    },
    label: {
      color: theme.colors.blue_gray[9],
    },
    description: {
      color: theme.colors.blue_gray[5],
    },
    selectedIcon: {
      position: 'absolute',
      top: theme.spacing.md,
      right: theme.spacing.md,
      zIndex: 1,

      svg: {
        color: theme.white,
        fill: theme.colors.blue_accent[4],
      },
    },
    badge: {
      position: 'absolute',
      zIndex: 1,
      top: theme.spacing.md,
      left: '50%',
      transform: 'translateX(-50%)',
      textTransform: 'unset',
      fontSize: theme.fontSizes.xs,
    },
    contactLink: {
      marginTop: theme.spacing.xl,
      flexGrow: 1,
      display: 'flex',
      alignItems: 'flex-end',
    },
  })
);
