import { Descope, getSessionToken } from '@descope/react-sdk';
import { createStyles, Stack } from '@mantine/core';
import { jwtDecode } from 'jwt-decode';
import React, { ComponentPropsWithoutRef } from 'react';

import { Pendo } from '@portals/analytics';
import { useCreateUserForTenant } from '@portals/api/onboarding';
import {
  captureDescopeError,
  sendDescopeErrorReport,
} from '@portals/framework';

import { AuthorizedDesktop } from './Authorized/Authorized.desktop';
import { OnboardingJWT } from '../../../types/common';
import { useOnboardingWizard } from '../../../use-onboarding-wizard';
import { showErrorNotification } from '../../common';

export function AuthenticationDesktop() {
  const { classes } = useStyles();
  const { setContextData, onNext, contextData } = useOnboardingWizard();

  const createUserForTenant = useCreateUserForTenant();

  const onSubmit: ComponentPropsWithoutRef<
    typeof Descope
  >['onSuccess'] = async (e) => {
    const sessionToken = getSessionToken();

    const parsedJWT = jwtDecode<OnboardingJWT>(e.detail.sessionJwt);

    try {
      const auth = await createUserForTenant.mutateAsync({
        token: sessionToken,
      });

      setContextData((curr) => ({
        ...curr,
        email: e.detail.user?.email,
        auth,
        companyName: parsedJWT.company_name,
      }));

      onNext();
    } catch (error) {
      showErrorNotification((e as any)?.error);
    }
  };

  if (contextData.auth) {
    return <AuthorizedDesktop />;
  }

  return (
    <Stack className={classes.container}>
      <Descope
        flowId="onboarding"
        onSuccess={onSubmit}
        onError={captureDescopeError}
        logger={{
          debug: (title: string, description?: string) => {
            return;
          },
          info: (title, description, state) => {
            sendDescopeErrorReport(state, 'Onboarding > AuthenticationDesktop');

            if (state?.error) {
              Pendo.track(
                `Descope: Failed - ${state?.screen?.state?.errorText}`,
                {
                  email: state?.screen?.state?.form?.email,
                  project: state?.screen?.state?.project?.name,
                  domain: window.location.origin,
                }
              );
            }
          },
          warn: () => {
            return;
          },
          error: () => {
            return;
          },
        }}
      />
    </Stack>
  );
}

const useStyles = createStyles((theme) => ({
  container: {
    width: '400px',
  },
}));
