import React from 'react';

import { WizardSteps, WizardTransitionType } from '@portals/framework';

import { Authentication } from '../components/steps/Authentication';
import { Congratulations } from '../components/steps/Congratulations';
import { TenantTypeSelection } from '../components/steps/TenantTypeSelection';
import { OnboardingWizardContextDataType, StepIdEnum } from '../types/wizard';

export const STEPS: WizardSteps<OnboardingWizardContextDataType, StepIdEnum> = {
  [StepIdEnum.Authentication]: {
    id: StepIdEnum.Authentication,
    component: <Authentication />,
    isFinalStep: false,
    [WizardTransitionType.Next]: {
      transition: { id: StepIdEnum.TenantTypeSelection },
    },
  },
  [StepIdEnum.TenantTypeSelection]: {
    id: StepIdEnum.TenantTypeSelection,
    component: <TenantTypeSelection />,
    isFinalStep: false,
    [WizardTransitionType.Next]: {
      transition: { id: StepIdEnum.Congratulations },
      isDisabled: ({ contextData }) => !contextData.classification,
    },
    [WizardTransitionType.Prev]: {
      transition: { id: StepIdEnum.Authentication },
      isDisabled: () => false,
    },
  },
  [StepIdEnum.Congratulations]: {
    id: StepIdEnum.Congratulations,
    component: <Congratulations />,
    isFinalStep: true,
    [WizardTransitionType.Next]: {
      isDisabled: () => true,
    },
    [WizardTransitionType.Prev]: {
      isDisabled: () => true,
    },
  },
};
