import { Box, createStyles } from '@mantine/core';
import { Notifications } from '@mantine/notifications';
import React from 'react';
import { BrowserRouter, Route, Routes } from 'react-router-dom';

import { QueryClientWrapper } from '@lib/query-client-wrapper';
import { MantineThemeProvider } from '@portals/core';
import { Page404 } from '@portals/framework';
import ThemeProvider from '@portals/ui/ThemeProvider';

import { Meta } from './app/components/Meta';
import { Onboarding } from './app/components/Onboarding';

const basename = process.env.NX_PREVIEW_APP_NAME
  ? `/${process.env.NX_PREVIEW_APP_NAME}`
  : '/';

const App = () => {
  const { classes } = useStyles();

  return (
    <QueryClientWrapper>
      <Meta title="Xyte - Registration" />

      <ThemeProvider>
        <MantineThemeProvider>
          <Notifications position="bottom-center" />

          <BrowserRouter basename={basename}>
            <Box className={classes.container}>
              <Routes>
                <Route path="/" element={<Onboarding />} />
                <Route path="*" element={<Page404 />} />
              </Routes>
            </Box>
          </BrowserRouter>
        </MantineThemeProvider>
      </ThemeProvider>
    </QueryClientWrapper>
  );
};

const useStyles = createStyles((theme) => ({
  container: {
    width: '100vw',
    height: '100vh',
    overflow: 'hidden',
  },
}));

export default App;
